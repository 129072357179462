import {Box, Button, useTheme} from "@mui/material";
import * as React from "react";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tokens} from "../../../theme";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import './orders.css';
import {ordersScheme} from "../../UI/Tables/dash_scheme";

const SelectOrder = ({orders, deleteOrder}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [
        ...ordersScheme,
        {
            "field": "delete",
            "headerName": "Удалить предписание",
            "flex": 1,
            "align": "center",
            "renderCell": (params) => {
                return (
                    <Button
                        variant="contained"
                        startIcon={<DeleteRoundedIcon />}
                        color="warning"
                        onClick={() => deleteOrder(params.row)}
                    >
                        Удалить
                    </Button>
                );
            },
        }
    ];
    // console.log('\n columns', columns);

    return (
            <Box
                m="40px 0 0 0"
                height="70vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={orders}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
    );
};

export default SelectOrder;