import axios from "axios";
import {api, headersRequests} from "./Settings";
import {ErrorOccurred} from "./ErrorOccurred";

const answerErrors = (data, status) => {

    const message = data?.errors ?
        data?.errors
        .map(e => `${e?.attr && "attr: " + e?.attr}, ${e?.code && "code: " + e.code}, ${e?.detail && "detail: " + e.detail}`)
        .join(', ') :
        `Статус: ${status}`
    ;

    return {
        severity: "error",
        message,
    };
}

export const getTariffsTemplate = async (token) => {

    try {
        const tariffs = await axios.get(
            `${api}/tariffs/`,
            headersRequests('get', token)
        );
        const allTariffs = tariffs?.data?.map(tariff => {

            // console.log('\n tariff', tariff);

            return (tariff);
        })
        // console.log('\n getTariffs', allTariffs);
        return (allTariffs);
    }
    catch (error) {

        console.log('\n error patchTariffTemplate', error.response);

        if (error.response.data) {
            // console.log('\n ', error.response.data);
            return (answerErrors(error.response.data));
        }
    }
}

export const addTariffsTemplate = async (token, data) => {

    try {

        console.log('\n data', data);

        const response = await axios.post(
            `${api}/tariffs/`,
            data,
            headersRequests('post_form', token)
        );

        // console.log('\n patchTariffTemplate', response.data);

        return {
            answer: {
                severity: 'success',
                message: 'Тарифный шаблон успешно изменен',
            },
            data: response.data
        }
    }
    catch (error) {

        console.log('\n error addTariffsTemplate', error.response);

        if (error.response.data) {
            // console.log('\n ', error.response.data);
            return (answerErrors(error.response.data));
        }
    }
}

export const patchTariffTemplate = async (token, data, id) => {

    try {

        // console.log('\n data', [...new Set(data)]);

        const response = await axios.patch(
            `${api}/tariffs/${id}/`,
            data,
            headersRequests('post_form', token)
        );

        // console.log('\n patchTariffTemplate', response.data);

        return {
            answer: {
                severity: 'success',
                message: 'Тарифный шаблон успешно изменен',
            },
            data: response.data
        }
    }
    catch (error) {

        console.log('\n error patchTariffTemplate', error.response);

        if (error.response.data) {
            // console.log('\n ', error.response.data);
            return (answerErrors(error.response.data));
        }
    }
}

export const deleteTariffsTemplate = async (token, id) => {

    try {
        const response = await axios.delete(
            `${api}/tariffs/${id}/`,
            headersRequests('post_form', token)
        );
        const data = response?.data?.sort((a, b) => a.id - b.id).sort((a, b) => a.percent - b.percent);
        return (data);
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка удаления тарифов', 'deleteTariffs');
    }
    return [];
}

export const getDiscounts = async (token) => {

    try {
        const response = await axios.get(
            `${api}/payments/discounts/`,
            headersRequests('get', token)
        );
        return (response.data);
    }
    catch (error) {

        console.log('\n error getDiscounts', error.response);

        if (error.response.data) {
            // console.log('\n ', error.response.data);
            return (answerErrors(error.response.data));
        }
    }
}

export const addDiscounts = async (token, data) => {

    console.log('\n ', token, data);

    try {

        const response = await axios.post(
            `${api}/payments/discounts/`,
            data,
            headersRequests('post_form', token)
        );

        // console.log('\n patchTariffTemplate', response.data);

        return {
            answer: {
                severity: 'success',
                message: 'Скидка успешно изменена',
            },
            data: response.data
        }
    }
    catch (error) {

        console.log('\n error getDiscounts', error.response);

        if (error?.response?.data?.errors) {
            // console.log('\n ', error.response.data);
            return (answerErrors(error.response.data));
        } return (answerErrors(null,error.response.status))
    }
}

export const patchDiscounts = async (token, id, data) => {

    console.log('\n ', token, id, data);

    try {

        const response = await axios.patch(
            `${api}/payments/discounts/${id}/`,
            data,
            headersRequests('post_form', token)
        );

        // console.log('\n patchTariffTemplate', response.data);

        return {
            answer: {
                severity: 'success',
                message: 'Скидка успешно изменена',
            },
            data: response.data
        }
    }
    catch (error) {

        console.log('\n error getDiscounts', error.response);

        if (error?.response?.data?.errors) {
            // console.log('\n ', error.response.data);
            return (answerErrors(error.response.data));
        } return (answerErrors(null,error.response.status))
    }
}

export const deleteDiscounts = async (token, id) => {

    try {

        const response = await axios.delete(
            `${api}/payments/discounts/${id}/`,
            headersRequests('post_form', token)
        );

        // console.log('\n patchTariffTemplate', response.data);

        return {
            answer: {
                severity: 'success',
                message: 'Скидка успешно удалена',
            },
            data: response.data
        }
    }
    catch (error) {

        console.log('\n error deleteDiscounts', error.response);

        if (error?.response?.data?.errors) {
            // console.log('\n ', error.response.data);
            return (answerErrors(error.response.data));
        } return (answerErrors(null,error.response.status))
    }
}

export const tariffsProps = {
    rolesList: [
        { name: "administrator", label: "Администратор" },
        { name: "build_control", label: "Стройконтроль" },
        { name: "contract", label: "Лот тендера с подрядчиком" },
        { name: "coordinator", label: "Координатор" },
        { name: "gip", label: "ГИП" },
        { name: "manager", label: "Начальник участка" },
    ],
    name: "Наименование шаблона",

};