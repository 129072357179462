import {Alert, Box, Button, Typography, useTheme} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import React, {useEffect, useState} from "react";
import Page from "../Company/Page";
import {
    deleteOrder,
    getOrdersByTender,
} from "../../methods/subcompaniesMethods";
import SelectOrder from "./SelectOrder";
import AskUser from "../../UI/Modal/AskUser";
import Modal from "@mui/material/Modal";
import {tokens} from "../../../theme";

export default function OrdersByTender({token}) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const params = new URLSearchParams(useLocation().search);
    const company_id = +params.get('company_id');
    const project_id = +params.get('project_id');
    const subcompany_id = +params.get('subcompany_id');
    const tender_id = +params.get('tender_id');

    const [orders, setOrders] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [modal, setModal] = useState(null);

    useEffect(() => {

        const getOrders = async () => {

            const response = await getOrdersByTender(token, company_id, project_id, subcompany_id, tender_id);
            // console.log('\n ', response);
            if (response?.success) {
                setOrders(response.data);
            }
            else setAnswer(response);
        }

        getOrders();
    }, [token, company_id, project_id, subcompany_id, tender_id]);

    const deleteOrderHandler = (order) => {

        setAnswer(null);

        const deleteOrderRequest = async (token, order) => {

            setModal(null);

            const response = await deleteOrder(token, order, company_id, project_id);
            if (response?.success) {
                // console.log('\n ', response);
                setAnswer({
                    success: true,
                    message: response.data
                });

                const getOrders = await getOrdersByTender(token, company_id, project_id, subcompany_id, tender_id);
                if (getOrders?.success) {
                    setOrders(getOrders.data);
                }
            } else {
                setAnswer(response);
            }
        }

        setModal(
            <Box className={`flex flex-col gap-2`}>
                <Typography variant={'h4'}>
                    Вы действительно хотите удалить предписание?
                </Typography>
                <AskUser
                    success={() => deleteOrderRequest(token, order)}
                    reject={() => setModal(null)}
                />
            </Box>
        );
    }

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.grey[900],
        color: colors.grey[100],
        width: '350px',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Page
            label="Предписания тендера"
        >
            {modal && <Modal
                open={!!modal}
                onClose={() => setModal(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>{modal}</Box>
            </Modal>}
            {answer && <Alert sx={{mx: 2}} severity={answer.success ? "success" : "error"}>
                {answer.success ? "Данные успешно обновлены" : answer.message}
            </Alert>}
            <Box className={`flex flex-row gap-2`}>
                <Button
                    color="info"
                    startIcon={<ArrowBackRoundedIcon />}
                    variant="contained"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/lots/`}
                    sx={{ width: "max-content" }}
                >
                    Назад
                </Button>
            </Box>
            {orders && <SelectOrder
                orders={orders}
                deleteOrder={deleteOrderHandler}
            />}
        </Page>
    )
}