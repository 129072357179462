import { Alert, Box, Button, FormControlLabel, Switch, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../theme";
import { Price } from "../../UI/global/Price";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {tariffsProps} from "../../methods/TariffsData";
import {fileServer} from "../../methods/Settings";

export default function DetailTariffs({ tariff, updateTariffTemplate, message }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isEditable, setIsEditable] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const keys = tariffsProps;

    const handleUpdateTemplate = (e) => {
        e.preventDefault();
        setIsChanged(false);
        setIsEditable(false);
        updateTariffTemplate(new FormData(e.target), tariff.id);
    };

    return (
        <Box className="flex gap-3 flex-col">
            <form className="w-full flex gap-4 flex-col" onSubmit={handleUpdateTemplate}>
                {message && <Alert severity={message.severity} sx={{ fontSize: 17 }}>{message.message}</Alert>}
                <Box className="p-2 border rounded-md w-max" sx={{ color: colors.warning[200], borderColor: colors.warning[100] }}>
                    <Alert severity="info" sx={{ fontSize: 17 }}>Стоимость в месяц {tariff?.payment_per_month} р.</Alert>
                    <FormControlLabel
                        control={<Switch checked={isEditable} color="error" onChange={() => setIsEditable(!isEditable)} />}
                        label="Редактировать"
                    />
                </Box>
                {isEditable && (
                    <TextField
                        variant="outlined"
                        label={keys.name}
                        defaultValue={tariff.name}
                        onChange={() => setIsChanged(true)}
                        fullWidth
                        name="name"
                    />
                )}
                <table className="w-full md:text-xl max-h-[20vh] overflow-y-auto">
                    <thead>
                    <tr>
                        <th>Роль</th>
                        <th>Количество</th>
                        <th>Цена</th>
                    </tr>
                    </thead>
                    <tbody>
                    {keys.rolesList.map(({ name, label }, index) => (
                        <tr key={index}>
                            <td className="p-2">{label}</td>
                            <td className="text-center p-2">
                                {isEditable ? (
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        defaultValue={tariff[name] || 1}
                                        onChange={() => setIsChanged(true)}
                                        name={name}
                                        fullWidth
                                    />
                                ) : (
                                    <span>{tariff[name]}</span>
                                )}
                            </td>
                            <td className="text-center">
                                {isEditable ? (
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        defaultValue={tariff[`${name}_price`] || 1}
                                        onChange={() => setIsChanged(true)}
                                        inputProps={{ step: 0.01, min: 0.01 }}
                                        name={`${name}_price`}
                                        fullWidth
                                    />
                                ) : (
                                    Price(tariff[`${name}_price`])
                                )}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={3} className={`py-2`}>
                            {isEditable ? (
                                <TextField
                                    variant="outlined"
                                    label="Описание"
                                    type="text"
                                    defaultValue={tariff.description}
                                    onChange={() => setIsChanged(true)}
                                    fullWidth
                                    name="description"
                                />
                            ) : (
                                "Описание " + tariff?.description
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3} className={`py-2`}>
                            {isEditable ?
                                (
                                    <TextField
                                        variant="outlined"
                                        label="Изображение"
                                        type="file"
                                        onChange={() => setIsChanged(true)}
                                        fullWidth
                                        name="image"
                                    />
                                )
                                : (
                                <img
                                    src={`${fileServer}${tariff?.image}`}
                                    loading="lazy"
                                />
                            )}
                        </td>
                    </tr>
                    </tbody>
                </table>
                {isChanged && (
                    <Box className="mx-auto w-max">
                        <Button
                            color="warning"
                            variant="contained"
                            type="submit"
                            startIcon={<SaveOutlinedIcon />}
                        >
                            Сохранить
                        </Button>
                    </Box>
                )}
            </form>
        </Box>
    );
}