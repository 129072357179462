import {Alert, Box, Button, TextField} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {useState} from "react";

export default function EditDiscountModal ({data, onsubmit, message, is_new}) {
    
    console.log('\n ', data);
    // if (message) console.log('\n message', message);

    const [isChanged, setIsChanged] = useState(false);

    if (data) return (
        <Box className="flex gap-3 flex-col">
            {message && <Alert severity={message.severity} sx={{ fontSize: 17 }}>{message.message}</Alert>}
            <form className="w-full flex gap-4 flex-col" onSubmit={onsubmit}>
                <Box className={`grid md:grid-cols-3 gap-2`} sx={{ fontSize: 17 }}>
                    <TextField
                        variant="outlined"
                        type="number"
                        label="Количество от" // сотрудников на роль
                        defaultValue={data?.amount_from || ""}
                        onChange={() => setIsChanged(true)}
                        name="amount_from"
                    />
                    <TextField
                        variant="outlined"
                        type="number"
                        label="Количество до"
                        defaultValue={data?.amount_to || ""}
                        onChange={() => setIsChanged(true)}
                        name="amount_to"
                    />
                    <TextField
                        variant="outlined"
                        type="number"
                        label="Процент скидки"
                        defaultValue={data?.percent || ""}
                        onChange={() => setIsChanged(true)}
                        name="percent"
                    />
                </Box>
                {isChanged && (
                    <Box className="mx-auto w-max">
                        <Button
                            color="warning"
                            variant="contained"
                            type="submit"
                            startIcon={<SaveOutlinedIcon />}
                        >
                            {is_new ? "Создать" : "Сохранить"}
                        </Button>
                    </Box>
                )}
            </form>
        </Box>
    );
}