import {Alert, Box, Button, useTheme} from "@mui/material";
import Header from "../../Header";
import { tokens } from "../../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {contractStatuses} from "../../UI/global/stauses";
import Modal from "@mui/material/Modal";
import {EditLocation} from "@mui/icons-material";
import {Link} from "react-router-dom";

const SelectSubcompany = ({ tenders, resetPeriodsHandler, changeLotStatusHandler, message, modal, setModal}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const columns = [
        {
            field: "company_id",
            headerName: "ID Компинии",
            type: "number",
            "align": "center",
            "headerAlign": "center",
        },
        {
            field: "project_id",
            headerName: "ID Проекта",
            type: "number",
            "align": "center",
            "headerAlign": "center",
        },
        {
            field: "subcompany",
            headerName: "ID Подрядчика",
            type: "number",
            "align": "center",
            "headerAlign": "center",
        },
        {
            field: "id",
            headerName: "ID тендера",
            "align": "center",
            "headerAlign": "center",
        },
        {
            field: "company_name",
            headerName: "Компиния",
            flex: 1,
            "headerAlign": "center",
        },
        {
            field: "project_name",
            headerName: "Проект",
            flex: 1,
            "headerAlign": "center",
        },
        {
            field: "subcompany_name",
            headerName: "Подрядчик",
            flex: 1,
            "headerAlign": "center",
        },
        {
            field: "name",
            headerName: "Тендер",
            flex: 1,
            cellClassName: "name-column--cell",
            "headerAlign": "center",
        },
        {
            field: "is_won",
            headerName: "Статус договора",
            // type: "boolean",
            "headerAlign": "center",
            flex: 1,
            renderCell: (params) => {

                // console.log('\n params', params.row);

                return (
                    <FormControl style={{width: '220px'}}>
                        <Select
                            labelId="material"
                            name="material"
                            label="Статус контракта"
                            value={params.row.contract.contract || ''}
                            onChange={(e) => {
                                // console.log('params.row.tender.id перед вызовом changeLotStatusHandler:', params.row.tender?.id);
                                changeLotStatusHandler(
                                    +e.target.value,
                                    params.row.company_id,
                                    params.row.project_id,
                                    params.row.subcompany,
                                    params.row.tender?.id
                                );
                            }}
                        >
                            {Object.keys(contractStatuses).map(s => <MenuItem key={s} value={s}>{contractStatuses[s]}</MenuItem>)}
                        </Select>
                    </FormControl>
                );
            },
        },
        {
            field: "reset",
            headerName: "Сбросить период",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        startIcon={<CalendarMonth />}
                        color="warning"
                        onClick={() => resetPeriodsHandler(
                            params.row.company_id,
                            params.row.project_id,
                            params.row.subcompany,
                            params.row.tender?.id
                        )}
                    >
                        Сбросить
                    </Button>
                );
            },
            "headerAlign": "center",
        },
        {
            field: "",
            headerName: "Предписания по тендеру",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        startIcon={<EditLocation />}
                        color="info"
                        component={Link}
                        to={`/orders-by-tender/?company_id=${params.row.company_id}&project_id=${params.row.project_id}&subcompany_id=${params.row.subcompany}&tender_id=${params.row.tender.id}`}
                    >
                        Предписания
                    </Button>
                );
            },
            "headerAlign": "center",
        },
    ];
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.grey[900],
        color: colors.grey[100],
        width: '350px',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box
            m="20px"
            display="flex"
            flexDirection="column"
        >
            <Header
                title="Список тендеров"
                subtitle="в которых участвует подрядчик"
                flex="none"
            />
            {message && <Alert severity={message.success ? "success" : "error"}>
                {message.message}
            </Alert>}
            {modal && <Modal
                open={!!modal}
                onClose={() => setModal(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>{modal}</Box>
            </Modal>}
            <Box
                m="40px 0 0 0"
                height="70vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={tenders}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );
};

export default SelectSubcompany;
